<template>
  <div>
    <div id="container-terms">
      <b-button
        variant="primary"
        id="back"
        class="rounded-circle px-2 left-top"
        @click="routerGoBack()"
      >
        <b-icon icon="chevron-left" scale="1"></b-icon>
      </b-button>
      <h3>Allgemeine Geschäftsbedingungen</h3>
      <h2>der digital animals GmbH in der Fassung vom 01.03.2021</h2>
      Nachfolgende Allgemeine Geschäftsbedingungen regeln die rechtlichen
      Beziehungen zwischen der digital animals GmbH (nachfolgend „Agentur“
      genannt), Katernberger Straße 107, 45327 Essen, Deutschland und ihren
      geschäftlichen Kunden.
      <br />
      <br />
      <h4>§ 1 Geltungsbereich</h4>
      Sämtliche Angebote, Lieferungen und Leistungen erfolgen ausschließlich
      aufgrund der nachstehenden Geschäftsbedingungen. Diese AGB gelten auch
      ohne nochmalige ausdrückliche Vereinbarung für alle künftigen
      Geschäftsbeziehungen. Spätestens mit der Entgegennahme des Angebotes, der
      Lieferung oder Leistung gelten diese Bestimmungen, selbst im Falle eines
      vorangegangenen Widerspruchs, als vorbehaltlos angenommen.
      Entgegenstehenden Bedingungen wird hiermit widersprochen. Abweichungen von
      diesen Geschäftsbedingungen bedürfen zu ihrer Wirksamkeit der
      ausdrücklichen schriftlichen Bestätigung für jeden einzelnen Vertrag.
      <br />
      <br />
      <h4>§2 Angebot, Vertragsschluss, Angebotsunterlagen</h4>
      (1) Basis für den Vertragsabschluss ist das jeweilige Angebot der Agentur,
      in dem der Leistungsumfang und die Vergütung festgehalten sind.
      <br />
      <br />
      (2) Der Kunde kann das Angebot durch Erklärung in Textform annehmen.
      Befristete Angebote können nur im Laufe der Annahmefrist angenommen
      werden; anschließend gilt die Annahme als neues Angebot, dessen Annahme
      sich die Agentur vorbehält.
      <br />
      <br />
      (3) Einzelaufträge sowie Leistungen im Rahmen laufender Arbeiten wie z.B.
      elektronische Bildbearbeitung und dergleichen bedürfen bis zu maximal €
      750 netto nicht der Vorlage von Kostenvoranschlägen und können
      insbesondere mündlich oder per einfacher formloser E-Mail vereinbart
      werden. Ebenfalls keiner gesonderten Vereinbarung bedürfen Abweichungen
      der vom Kunden freigegebenen Kostenvoranschläge bis zu 15 % der
      vorausgeschätzten Kosten, wenn sich im Rahmen der Auftragsausführung
      Änderungen des zuvor geschätzten Aufwandes ergeben.
      <br />
      <br />
      (4) Ein Vertragsschluss mit Verbrauchern wird von der Agentur nicht
      akzeptiert.
      <br />
      <br />
      (5) Die Gegenzeichnung von Angeboten versteht sich als Erklärung der
      Geschäftsführung des Vertragspartners. Der Kunde ist für den gesetzten
      Rechtsschein verantwortlich. Dies gilt auch dann, wenn die Gegenzeichnung
      ohne Stempelabdruck erfolgt, sofern die Erklärung der Sphäre des Kunden
      grundsätzlich zurechenbar ist.
      <br />
      <br />
      <h4>§3 Vertragsgegenstand, Mitwirkungspflichten</h4>
      (1) Der Vertragsgegenstand bemisst sich zunächst nach den Regelungen des
      Angebotes. Die vorliegenden AGB dienen zur Auslegung des Vertragsinhaltes.
      <br />
      <br />
      (2) Die Agentur ist berechtigt, Arbeiten, die im Namen und für Rechnung
      des Kunden an Dritte vergeben werden sollen / können, durch eigene
      Mitarbeiter (Eigenleistungen) auszuführen und mit dem Kunden abzurechnen.
      <br />
      <br />
      (3) Der Abschluss aller zur Durchführung des jeweiligen Vertrages
      notwendigen Verträge erfolgt im Namen und im Auftrag des Kunden. Die
      Agentur wird hierdurch vom Kunden bevollmächtigt, alle Verträge, die zur
      Durchführung und Erfüllung des jeweiligen Vertrages notwendig oder
      zumindest zweckmäßig sind, im Namen des Kunden abzuschließen und die
      Zahlungen aus dem Budget im Namen des Kunden zu leisten. Die
      Zahlungsverpflichtungen, die die Agentur im Namen des Kunden eingeht,
      dürfen den Kostenrahmen um nicht mehr als 15 % überschreiten. Soweit
      Verträge für den Kunden über Fremdleistungen namens sowie auf Rechnung der
      Agentur abgeschlossen werden, ist der Kunde verpflichtet, die Agentur im
      Innenverhältnis von sämtlichen Verbindlichkeiten freizustellen, die sich
      hieraus ergeben. Eine Haftung bezüglich der Dritt-Leistungen wird von der
      Agentur nicht übernommen.
      <br />
      <br />
      (4) Der Kunde hat die Agentur unverzüglich mit allen Informationen sowie
      Unterlagen zu versorgen, die für die Erbringung der Leistung erforderlich
      sind und steht für die Richtigkeit, Zulässigkeit und Rechtzeitigkeit
      dieser Inhalte ein. Der Kunde wird die Agentur über alle Vorgänge
      informieren, die für die Durchführung des Auftrages von Bedeutung sind,
      auch wenn diese Umstände erst während der Durchführung des Auftrags
      bekannt werden. Der Kunde trägt deshalb den Aufwand, der dadurch entsteht,
      dass Arbeiten infolge seiner unrichtigen, unvollständigen oder
      nachträglich geänderten Angaben von der Agentur wiederholt oder geändert
      werden müssen oder verzögert werden.
      <br />
      <br />
      (5) Der Kunde ist weiterhin verpflichtet, die für die Durchführung des
      Auftrages zur Verfügung gestellten Unterlagen (Fotos, Logos, Datensätze,
      weitere Informationen etc.) auf eventuell bestehende
      Urheber-Kennzeichenrechte oder sonstige Rechte Dritter zu prüfen. Die
      Agentur haftet nicht wegen einer Verletzung derartiger Rechte. Wird die
      Agentur wegen einer solchen Rechtsverletzung in Anspruch genommen, so hält
      der Kunde die Agentur schad- und klaglos.
      <br />
      <br />
      (6) Wenn die Agentur für den Kunden die Betreuung von Kundenkonten auf
      Portalen Dritter übernimmt und hierfür die Einrichtung etwaiger Konten
      oder Profile erforderlich ist, ist die Agentur ermächtigt, diese für den
      Kunden einzurichten. Im Falle der Beendigung des Vertrages ist die Agentur
      zur Löschung / Abschaltung der Konten / Profile befugt, sofern die
      Parteien keine andere Regelung treffen.
      <br />
      <br />
      (7) Wird die Agentur mit der Schaltung von Spots oder der Buchung
      sonstiger Werbeflächen beauftragt, vergibt sie alle Aufträge an die
      Werbeträger im Namen und auf Rechnung des Kunden. Die Agentur sorgt für
      die fristgemäße Lieferung der vom Kunden freigegebenen Werbemittel an die
      Werbeträger und überwacht die ordnungsgemäße Auftragsabwicklung.
      <br />
      <br />
      (8) Die Leistungserbringung bei gestalterischen Arbeiten erfolgt im Rahmen
      eines dynamischen Entwurfsprozesses oder nach den Vorgaben des
      Kostenvoranschlages / Angebotes bzw. auf Basis eines ersten Briefings. Im
      Übrigen besteht Gestaltungsfreiheit. Während des Entwurfsprozesses erfolgt
      eine fortlaufende Korrespondenz zwischen Agentur und Kunden über Fortgang
      und Ziel des Projekts. Im Übrigen erarbeitet die Agentur, wenn nicht im
      Angebot anders festgelegt, bis zu 2 Entwürfe, von denen der Kunde einen
      auswählen kann und der sodann weiter ausgearbeitet wird.
      Gestaltungsarbeiten beinhalten eine Korrektur- / Änderungsschleife. Jede
      weitere Korrektur-/Änderungsschleife wird nach Aufwand berechnet.
      Sonderleistungen wie die Umarbeitung oder Änderung von Reinzeichnungen
      oder Drucküberwachung werden nach Zeitaufwand gesondert berechnet.
      Vorschläge oder Mitarbeit des Kunden bzw. seiner Mitarbeiter haben keinen
      Einfluss auf die Höhe der Vergütung; sie begründen bei gestalterischen
      Tätigkeiten kein Miturheberrecht.
      <br />
      <br />(9) Der Kunde ist – vorbehaltlich einer expliziten anderweitigen
      Vereinbarung - dafür verantwortlich, vor Auftragsbeginn sämtliche
      erforderlichen Film- und Fotogenehmigung einzuholen. Dies betrifft unter
      anderem alle urheberrechtlichen geschützte Darstellungen von Kunstwerken
      als auch die Zustimmung / Genehmigung von Behörden, Beamten, Veranstalter,
      Betreiber, Inhaber oder Eigentümer. Der Kunde ist ferner verpflichtet,
      Zugänge zu Räumlichkeiten für das Team der Agentur für etwaige Auf- und
      Abbauarbeiten frühzeitig zu ermöglichen
      <br />
      <br />
      <h4>§ 4 Leistungsänderungen</h4>
      (1) Änderungswünsche bestehender Verträge sind möglich und in Textform
      gegenüber der Agentur zu äußern. Die Agentur behält sich die Änderung nach
      den folgenden Regelungen vor. Gleichfalls wird die Agentur dem Kunden
      Kenntnis geben, falls eine Änderung des Vertrages im Hinblick auf die
      Durchführbarkeit des Projektes, insbesondere aus technischen,
      gestalterischen oder rechtlichen Gründen, erforderlich erscheint.
      <br />
      <br />
      (2) Die Agentur wird mitteilen welche Auswirkungen die gewünschte Änderung
      insbesondere hinsichtlich Vergütung, Mehraufwand und Terminen haben wird.
      Die Vertragsparteien werden sich über den Vorschlag für die Umsetzung des
      Änderungswunsches unverzüglich abstimmen und ggfls. eine
      Nachtragsvereinbarung schließen. Kommt eine Einigung nicht zustande oder
      endet das Änderungsverfahren aus einem anderen Grund, so verbleibt es beim
      ursprünglichen Leistungsumfang. Gleiches gilt für den Fall, dass der Kunde
      mit einer Änderung der Leistungen nicht einverstanden ist.
      <br />
      <br />
      (3) Die von dem Änderungsverfahren betroffenen Termine werden unter
      Berücksichtigung der Dauer der Prüfung, der Dauer der Abstimmung über den
      Änderungsvorschlag und gegebenenfalls der Dauer der auszuführenden
      Änderungswünsche zuzüglich einer angemessenen Anlauffrist, soweit
      erforderlich, verschoben. Die Agentur wird dem Kunden die neuen Termine
      mitteilen.
      <br />
      <br />
      (4) Für Verzögerungen, rechtliche oder technische Probleme oder sonstige
      nachteilige Auswirkungen auf den Leistungsgegenstand eines Projekts haftet
      die Agentur nicht, wenn sich der Kunde über den Vorschlag zur
      Leistungsänderung durch die Agentur hinweggesetzt hat.
      <br />
      <br />
      <h4>§ 5 Preise und Zahlungen</h4>
      (1) Sämtliche Leistungen der Agentur verstehen sich zzgl. der gesetzlichen
      Mehrwertsteuer.
      <br />
      <br />
      (2) Alle nicht bereits im Angebot aufgeführten und im Zusammenhang mit dem
      Auftrag stehenden notwendigen Aufwendungen und Auslagen der Agentur werden
      nach Aufwand abgerechnet.
      <br />
      <br />
      (3) Sofern keine Vergütungsvereinbarung im Einzelfall getroffen ist,
      gelten die jeweils üblichen Stunden- und Tagessätze der Agentur, im
      Zweifel gelten die Stunden- und Tagessätze als üblich, die bereits in
      einem anderen Projekt mit dem Kunden Anwendung gefunden haben. Ist das
      fragliche Projekt das erste Projekt der Parteien, richten sich die
      Stundensätze im Zweifel nach den veröffentlichten Stundensätzen eines
      Berufsverbandes für die jeweils in Frage stehende Leistung.
      <br />
      <br />
      (4) Die Angabe von Kostenrahmen ist, insbesondere bei Buchung von
      zusätzlichen Fremd- und Drittleistungen sowie bei Kreativleistungen, die
      bei Auftragsvergabe noch nicht abschließend bestimmt waren, unverbindlich.
      In diesen Fällen ist das Dienstvertragsrecht anwendbar. Die Agentur wird
      sich bemühen, realistische Kostenschätzungen abzugeben. Der Kunde wird bei
      voraussichtlichem Überschreiten von Kostenrahmen so früh wie möglich in
      Kenntnis gesetzt.
      <br />
      <br />
      (5) Bei Kostenüberschreitungen über 15 % werden sich die Parteien darüber
      verständigen, wer die Kostenerhöhung zu verantworten hat. Unabhängig von
      einer Lösung im Einzelfall ist der Kunde berechtigt, den Vertrag zu
      kündigen, wenn voraussichtliche Kosten um mehr als 15 % überschritten
      werden. Das Kündigungsrecht ist binnen 3 Wochen ab Kenntnis der
      Kostenüberschreitung auszuüben. Dem Kunden stehen aufgrund der
      Kostenüberschreitung oder der Kündigung keine weitergehenden Ansprüche zu.
      <br />
      <br />
      (6) Greift die Agentur vereinbarungsgemäß auf Leistungen Dritter zurück,
      hat die Agentur Anspruch auf Aufwendungsersatz. Dieser Anspruch schließt
      alle von der Agentur aufgewendeten inländischen und ausländischen Steuern
      und sonstigen Abgaben ein. Die Agentur ist berechtigt, vom Kunden für die
      von diesem zu ersetzenden Aufwendungen einen Vorschuss zu verlangen. Haben
      die Parteien in dem jeweiligen Einzelvertrag für von Fremdunternehmen zu
      erbringende Leistungen feste Beträge vereinbart, so ist die Agentur von
      der Verpflichtung zur Rechnungslegung befreit.
      <br />
      <br />
      (7) Gegenüber Ansprüchen der Agentur ist die Aufrechnung ausgeschlossen,
      es sei denn, die Gegenforderung ist rechtskräftig festgestellt,
      unbestritten oder von der Agentur anerkannt. Den Unternehmern steht die
      Einrede des nicht oder mangelhaft erfüllten Vertrages darüber hinaus zudem
      nur dann zu, wenn die Agentur bereits ein dem Wert der Leistung
      entsprechendes Entgelt erlangt hat oder im Verhältnis zu einem
      Subunternehmer selbst das entsprechende Zurückbehaltungsrecht ausüben
      kann.
      <br />
      <br />
      (8) Für GEMA-Gebühren, Künstlersozialversicherungsabgaben und Zollkosten
      ist der Kunde verantwortlich.
      <br />
      <br />
      (9) Der Honoraranspruch der Agentur entsteht für jede einzelne Leistung,
      sobald diese erbracht wurde. Die Agentur ist berechtigt, in angemessenen
      Zeitabständen Abrechnungen nach dem jeweiligen geleisteten Arbeitsaufwand
      und den angefallenen Auslagen vor- zunehmen.
      <br />
      <br />
      (10) Die Agentur ist ferner berechtigt, zur Deckung ihres Aufwandes
      Vorschüsse zu verlangen.
      <br />
      <br />
      <h4>§ 6 Haftung</h4>
      (1) Die Agentur haftet nicht für die Leistungsfähigkeit und
      Leistungsbereitschaft sowie Mängel der Leistung von Dritten, deren
      Beauftragten oder etwaig eingeschalteten Sponsoren, ebenso nicht für die
      Rechtzeitigkeit der Leistung dieser Personen oder sonstige
      Leistungsstörungen, die im Rahmen der Vertragsverhältnisse zu diesem
      Dritten auftreten können, sofern keine Haftung gem. § 831 BGB begründet
      ist. Sämtliche derartige Ansprüche stehen dem Kunden als direktem
      Auftraggeber zu.
      <br />
      <br />
      (2) Die Agentur haftet ohne gesonderte Vereinbarung nicht für den
      wirtschaftlichen Erfolg eines Vorhabens.
      <br />
      <br />
      (3) Vorstehende Haftungsausschlüsse gelten nur, soweit nicht ein Fall
      zwingender Haftung vorliegt. Diesbezüglich regelt die Agentur ihre Haftung
      wie folgt: Die Agentur schließt ihre Haftung für leicht fahrlässige
      Pflichtverletzungen aus. Dieser Haftungsausschluss gilt jedoch nicht für
      Verletzungen vertragswesentlicher Pflichten, Schäden aus der Verletzung
      des Lebens, des Körpers oder der Gesundheit oder von Garantien oder wenn
      Ansprüche nach dem Produkthaftungsgesetz berührt sind. Der
      Haftungsausschluss gilt auch für Pflichtverletzungen durch
      Erfüllungsgehilfen. Bei in sonstiger Weise verursachten Schäden haftet die
      Agentur bei Vorsatz und grober Fahrlässigkeit, auch ihrer
      Erfüllungsgehilfen, nach den gesetzlichen Bestimmungen.
      <br />
      <br />
      (4) In Fällen zwingender Haftung ist die Haftung der Höhe nach auf die bei
      Vertragsschluss vorhersehbaren und vertragstypischen Schäden begrenzt.
      <br />
      <br />
      (5) Das Risiko der rechtlichen Zulässigkeit der beauftragten Arbeiten
      trägt der Kunde. Diese Haftungsregel gilt insbesondere für den Fall, dass
      beauftragte Arbeiten gegen einschlägige Vorschriften des
      Wettbewerbsrechts, des Urheberrechts und der speziellen Werberechts- oder
      Telemediengesetze verstoßen. Die Agentur wird den Kunden bei Erkennen
      derartiger Probleme dennoch entsprechend hinweisen.
      <br />
      <br />
      (6) Der Kunde haftet für alle von ihm im Wege der Beauftragung oder
      Vertragsausführung getätigten Aussagen, gelieferte Inhalte, erteilte
      Freigaben und sonstige Materialien. Der Kunde haftet zudem für überlassene
      Unterlagen, die sich in seiner Verfügungsgewalt befinden. Insbesondere hat
      der Kunde für eine Sicherung vor Diebstahl und Beschädigung Sorge zu
      tragen und für etwaige Schäden durch Fremdeinwirkung aufzukommen, Der
      Kunde hat die im Vertrag festgehaltenen Daten für den Auf- und Abbau
      einzuhalten bzw. diesen zu ermöglichen; für Verzögerungen haftet der Kunde
      auf Basis der vereinbarten Preise.
      <br />
      <br />
      (7) Soweit die Agentur in Erfüllung dieses Vertrages im Namen des Kunden
      Verträge mit Dritten abschließt, beschränkt sich die auftragsgemäße
      Tätigkeit auf die Auswahl des betreffenden Vertragspartners und den
      Abschluss des betreffenden Vertrages unter Wahrung der in dem Vertrag
      gesetzten Grenzen. Die Agentur ist insbesondere nicht verpflichtet, die
      Durchführung solcher Verträge selbst zu überwachen. Derart von der Agentur
      beauftragte Dritte sind im Verhältnis der Agentur zum Kunden nicht
      Erfüllungsgehilfen oder Verrichtungsgehilfen der Agentur.
      <br />
      <br />
      (8) Bei Eintritt eines Haftungsfalls werden sich die Parteien die
      Gelegenheit zur Beseitigung des Haftungsstiftenden Ereignisses oder
      Umstandes gewähren und ggfls. das Recht auf Nachbesserung einräumen.
      <br />
      <br />
      <h4>§ 7 Gewährleistung</h4>
      (1) Erbrachte Leistungen hat der Kunde unverzüglich nach Erhalt, zu
      überprüfen. Bei offensichtlichen Mängeln ist die Mängelrüge innerhalb
      einer Ausschlussfrist von 10 Tagen nach Erhalt der Leistungen zu erheben.
      Anderenfalls sind diesbezügliche Ansprüche ausgeschlossen und eine etwaig
      erforderliche Abnahme wird fingiert. Die Regelung des § 377 HGB bleibt
      unberührt. Die erforderliche Frist gemäß § 640 Abs. 2 BGB gilt durch den
      hier aufgeführten Hinweis als gesetzt.
      <br />
      <br />
      (2) Im Falle eines Mangels steht der Agentur im Rahmen der gesetzlichen
      Regelungen die Wahl der Nacherfüllung zu. Die Agentur kann die
      Nacherfüllung jedoch verweigern, solange der Kunde die für die
      Erstellungsleistungen geschuldete Vergütung noch nicht vollständig gezahlt
      hat und der Kunde kein berechtigtes Interesse am Zurückbehalt der
      rückständigen Vergütung hat, insbesondere die zurückbehaltene oder aus
      anderem Grund nicht vollständig gezahlte Vergütung in Ansehung des
      nachzuerfüllenden oder mangelbehafteten Teils nicht in einem angemessenen
      Verhältnis steht.
      <br />
      <br />
      (3) Der Kunde wird die Agentur bei der Mangelfeststellung und -beseitigung
      unterstützen und unverzüglich Einsicht in Unterlagen gewähren, aus denen
      sich die näheren Umstände des Auftretens des Mangels ergeben.
      <br />
      <br />
      (4) Von der Gewährleistung ausgeschlossen sind Mängel, die auf falsche
      Informationen oder fehlerhafte, nicht rechtzeitige oder unterbliebene
      Mitwirkungshandlungen des Kunden oder von ihm beauftragter Dritter oder
      auf sonstige Lieferungen und Leistungen des Kunden zurückzuführen sind, es
      sei denn, der Kunde weist nach, dass die in Rede stehenden Mängel nicht
      durch die von ihm oder dem Dritten vorgenommene Veränderung, Bearbeitung
      oder vertragswidrige Nutzung verursacht wurden.
      <br />
      <br />
      (5) Im Übrigen gilt eine einjährige Verjährungsfrist für Mängelansprüche.
      <br />
      <br />
      (6) Die unter dem Punkt „Haftung“ getroffenen Haftungsausschlüsse sind
      auch im Rahmen der Gewährleistung anwendbar.
      <br />
      <br />
      (7) Der Kunde akzeptiert bei der Herstellung von Werbemitteln durch die
      Agentur die übliche Mehr- oder Minderauflage bis zu 10 %. Über etwaige
      Restmängel informiert die Agentur den Kunden unverzüglich. Soweit der
      Kunde innerhalb einer Frist von zwei Wochen nach Erhalt der Restmeldung
      keine Entscheidung trifft, ist die Agentur berechtigt, die Reste zu
      vernichten, ohne dass dem Kunden hieraus irgendwelche Ersatzansprüche
      erwachsen.
      <br />
      <br />
      <h4>§ 8 Kennzeichnung</h4>
      (1) Die Agentur ist berechtigt, auf allen Werbemitteln und bei allen
      Werbemaßnahmen auf die Agentur und allenfalls auf den Urheber hinzuweisen.
      <br />
      <br />
      (2) Die Agentur ist vorbehaltlich des jederzeit möglichen, schriftlichen
      Widerrufs des Kunden und vorbehaltlich einer anderweitigen Regelung in
      einer Geheimhaltungsvereinbarung dazu berechtigt, auf eigenen Werbeträgern
      und insbesondere auf ihrer Internetseite mit Namen und Firmenlogo auf die
      zum Kunden bestehende Geschäftsbeziehung und die konkret für den Kunden
      durchgeführten Projekte hinzuweisen.
      <br />
      <br />
      <h4>§ 9 Urheberrecht</h4>
      (1) Die Parteien vereinbaren, dass sämtliche gestalterischen Leistungen
      der Agentur (hierzu gehören insbesondere sämtliche Entwürfe, Vorlagen,
      Zeichnungen, Stories, Claims, Slogans, Texte, Markenbezeichnungen) dem
      Urheberrecht unterliegen. Die Parteien sind sich diesbezüglich darüber
      einig, dass die Agentur eine über die rein technische Arbeit hinausgehende
      geistig-kreative Leistung erbringt. Vorschläge und Weisungen des Kunden,
      seiner Mitarbeiter oder sonstiger Dritter begründen keinerlei
      Miturheberrecht.
      <br />
      <br />
      (2) Der Kunde erwirbt durch Zahlung des Honorars das Recht der Nutzung der
      vertragsgemäß erstellten Inhalte zum vereinbarten Zweck und im
      vereinbarten Nutzungsumfang, insbesondere soweit sich der Zweck aus dem
      Angebot bzw. dem Vertrag ergibt. Weitergehende Nutzungsrechtsabreden
      bedürfen der Vereinbarung.
      <br />
      <br />
      (3) Sofern nicht explizit etwas anderes vereinbart wurde, ist die Agentur
      berechtigt, sämtliche Ideen, Erfahrungen und sonstiges Know-How aus einem
      Projekt auch für Projekte mit anderen Kunden einzusetzen.
      <br />
      <br />
      <h4>§ 10 Präsentationen</h4>
      (1) Die Teilnahme an Präsentationen, insbesondere der Entwicklung
      konzeptioneller und gestalterischer Vorschläge durch die Agentur mit dem
      Zweck des Vertragsabschlusses mit dem Kunden erfolgt nur gegen ein
      angemessenes Honorar. Soweit eine Vereinbarung nicht zustande gekommen
      ist, umfasst der Anspruch auf das angemessene Honorar der Agentur
      zumindest den Personal- und Sachaufwand der Agentur für die Präsentation
      sowie die Kosten sämtlicher Fremdleistungen.
      <br />
      <br />
      (2) Das Präsentationshonorar wird im Falle der Erteilung des Auftrages auf
      die gesondert zu vereinbarende Agenturvergütung angerechnet. Mit der
      Zahlung des Präsentationshonorars erwirkt der Kunde keinerlei
      Verwertungs-, Eigentums- und Nutzungsrechte an den präsentierten
      Leistungen.
      <br />
      <br />
      (3) Soweit die Agentur nach der Präsentation keinen Auftrag erhält,
      bleiben alle Leistungen der Agentur, insbesondere die
      Präsentationsunterlagen und deren Inhalt im Eigentum der Agentur. Der
      Kunde ist nicht berechtigt, diese - in welcher Form auch immer - weiter zu
      nutzen. Etwaige Unterlagen im Zusammenhang mit der Präsentation sind
      unverzüglich der Agentur zurück zu übermitteln. Die Weitergabe von
      Präsentationsunterlagen an Dritte sowie deren Veröffentlichung,
      Vervielfältigung, Verbreitung oder sonstige Verwertung sowie
      Weiterverwendung ist ohne ausdrückliche Zustimmung der Agentur nicht
      zulässig. Das gilt auch für die Verwendung in geänderter oder bearbeiteter
      Form und für die Verwendung der den Leistungen der Agentur zugrunde
      liegenden Ideen, sofern diese in den bisherigen Werbemitteln des
      Auftraggebers vor der Präsentation keinen Niederschlag gefunden haben.
      <br />
      <br />
      (4) Kommt es zu keinem weiteren Vertragsschluss mit dem Kunden, ist die
      Agentur berechtigt, die im Zuge einer Präsentation eingebrachten Ideen und
      Konzepte der Agentur für die Lösung von Kommunikationsaufgaben anderweitig
      zu verwenden und zu verwerten.
      <br />
      <br />
      <h4>§ 11 Lieferung, Termine</h4>
      (1) Frist-Terminabsprachen, insbesondere solche, durch deren
      Nichteinhaltung eine Partei gemäß § 286 Abs. 2 ohne Mahnung in Verzug
      gerät, sind schriftlich festzuhalten und/oder zu bestätigen. Lieferfristen
      sind nur dann verbindlich, wenn der Kunde sämtliche Mitwirkungspflichten
      (z. B. Beschaffung von Unterlagen, Informationen, Freigaben, etc.)
      ordnungsgemäß erfüllt.
      <br />
      <br />
      (2) Sind von der Agentur Ausführungs- bzw. Fertigstellungsfristen
      angegeben und zur Grundlage für die Auftragserteilung gemacht worden,
      verlängern sich solche Fristen bei Streik und Fällen höherer Gewalt und
      zwar für die Dauer der Verzögerung.
      <br />
      <br />
      (3) Soweit eine nicht entschuldigte Verzögerung der vertraglich
      vereinbarten Ausführungs- und Fertigstellungsfristen eingetreten ist, ist
      der Kunde erst dann zur Geltendmachung der ihm gesetzlich zustehenden
      Rechte berechtigt, wenn er der Agentur eine angemessene, mindestens aber
      14 Tage währende Nachfrist gewährt hat. Diese Frist beginnt mit dem Zugang
      eines Mahnschreibens an die Agentur.
      <br />
      <br />
      <h4>§ 12 Sonstige Leistungsstörung, Rücktritt und Kündigung</h4>
      (1) Für alle Arbeiten der Agentur, die aus einem vom Kunden zu vertretenen
      Grund nicht zur Ausführung gebracht werden, gebührt der Agentur eine
      angemessene Vergütung.
      <br />
      <br />
      (2) Eine Frist zur Leistung oder Nacherfüllung kann nur dann nach
      erfolglosem Ablauf dieser Frist dazu genutzt werden, vom Vertrag
      zurückzutreten oder Schadenersatz statt der Leistung geltend zu machen,
      wenn die entsprechende Rechtsfolge bei der Fristsetzung mitgeteilt wurde
      und auch im Übrigen die gesetzlichen Gründe für einen Rücktritt vorliegen.
      <br />
      <br />
      (3) Tritt der Kunde wegen der Verletzung einer Pflicht, die sich auf eine
      abgrenzbare Leistung bezieht, zurück, so werden die anderen Leistungen von
      diesem Rücktritt nicht erfasst.
      <br />
      <br />
      (4) Ist mit dem Kunden im Rahmen eines Beratungs- oder sonstigen
      Dienstleistungsvertrages ein Mindestkontingent an Stunden vereinbart,
      bleibt der Kunde auch bei einem Rücktritt oder einer Kündigung zur
      Vergütung der vereinbarten Stunden verpflichtet, es sei denn, Rücktritt
      oder Kündigung erfolgen aufgrund eines Verschuldens der Agentur. Die
      Agentur hat sich aber dasjenige anrechnen zu lassen, was sie infolge der
      Aufhebung des Vertrages an Aufwendungen erspart oder durch anderweitige
      Verwendung ihrer Arbeitskraft erwirbt oder zu erwerben, böswillig
      unterlässt.
      <br />
      <br />
      (5) Jeder Vertragspartner hat das Recht, den Vertrag aus wichtigem Grund
      zu kündigen. Vor einer solchen Kündigung werden sich die Vertragspartner
      einander jedoch, soweit ihnen dies zugemutet werden kann, angemessen
      Gelegenheit geben, den Kündigungsgrund zu beseitigen.
      <br />
      <br />
      (6) Der Kunde kann Dienst- und Werkverträge bis zur vollständigen
      Erbringung der Leistung im Übrigen jederzeit kündigen. Wird der Vertrag
      aus einem vom Kunden zu vertretenden Grund gekündigt oder kündigt der
      Kunde aus einem von keiner der Parteien zu vertretenden Grund, so hat die
      Agentur Anspruch auf die vereinbarte Vergütung. Die Agentur muss sich
      jedoch das anrechnen lassen, was sie infolge der vorzeitigen Beendigung
      des Auftrages an Aufwendungen erspart hat. Das Gleiche gilt, wenn die
      Leistung infolge eines von der Agentur nicht zu vertretenden Grundes
      unmöglich geworden ist. Die Mindestvergütung in einem solchen Fall der
      Kündigung beträgt jedoch die Vergütung der schon erbrachten Leistungen
      zuzüglich 30 % der noch nicht erbrachten Leistungen und 100 % bereits
      entstandener Fremdkosten.
      <br />
      <br />
      (7) Wird die Durchführung von Leistungen aus Gründen unmöglich, die der
      Kunde zu vertreten hat, so behält die Agentur den Anspruch auf das
      vereinbarte Honorar. Die Agentur wird sich jedoch dasjenige anrechnen
      lassen, was infolge der Befreiung von der Leistung erspart und durch
      anderweitige Verwendung der Arbeitskraft erworben oder zu erwerben
      böswillig unterlassen wurde.
      <br />
      <br />
      (8) Wird die Leistungserbringung aus Gründen unmöglich, die keine der
      beiden Teile zu vertreten hat, so behält die Agentur den Anspruch auf die
      bereits fällig gewordenen Honoraranteile gemäß Zahlungsplan. Für die
      Leistungen der Agentur, die nach der zuletzt fällig gewordenen Rate gemäß
      Zahlungsplan erbracht wurden, steht der Agentur ein dieser Leistung
      entsprechender Honoraranteil zu.
      <br />
      <br />
      (9) Ist die Kündigung von der Agentur zu vertreten, besteht nur Anspruch
      auf Vergütung für die von der Agentur bis zur Beendigung des Vertrages
      erbrachten Leistungen, wenn auch die übrigen Voraussetzungen für die
      Vergütungspflicht vorliegen.
      <br />
      <br />
      (10) Wird ein Vertragspartner zahlungsunfähig oder wird ein
      Insolvenzverfahren über sein Vermögen eröffnet oder die Eröffnung mangels
      Masse abgelehnt, so ist der andere Vertragspartner berechtigt, das
      Vertragsverhältnis nach den vorstehenden Regelungen zu kündigen.
      <br />
      <br />
      <h4>§ 13 Datenschutz</h4>
      (1) Gesetzliche Datenschutzbestimmungen werden von der Agentur in ihrer
      jeweils geltenden Fassung eingehalten. Mitarbeiter werden nur dann
      Kenntnis von den Zugangsdaten oder Zugriff auf vom Kunden gespeicherte
      Daten erhalten, wenn dies zur Durchführung des jeweiligen
      Vertragsverhältnisses notwendig ist.
      <br />
      <br />
      (2) Die Agentur geht davon aus, dass der Kunde verantwortliche Stelle für
      etwaige Daten ist. Die Agentur sichert dem Kunden insoweit Mitwirkung am
      Abschluss einer Auftragsdatenverarbeitungsvereinbarung zu, geht jedoch
      davon aus, dass der Kunde derartige Vorgaben macht.
      <br />
      <br />
      <h4>§ 14 Rechtswahl, Gerichtsstand, Sonstiges</h4>
      (1) Sollte eine der Bestimmungen dieses Vertrages unwirksam sein oder
      werden, so wird hierdurch die Wirksamkeit der übrigen Bestimmungen nicht
      berührt. Die Parteien sind verpflichtet, eine unwirksame Bestimmung durch
      eine wirksame Bestimmung zu ersetzen, mit der das wirtschaftlich gewollte
      Ergebnis erreicht wird. Das gleiche gilt für Vertragslücken.
      <br />
      <br />
      (2) Es gilt das Recht der Bundesrepublik Deutschland mit Ausnahme des
      UN-Kaufrechts.
      <br />
      <br />
      (3) Ausschließlicher Gerichtsstand ist bei Verträgen mit Kaufleuten,
      juristischen Personen des öffentlichen Rechts oder öffentlich-rechtlichen
      Sondervermögen das für den Geschäftssitz der Agentur zuständige Gericht.
      <br />
      <br />
      Stand: 01.03.2021
      <br />
      <br />
    </div>
    <div id="back-to-top-button" class="right-bottom opacity-zero">
      <b-button
        variant="primary"
        id="back-to-top"
        class="rounded-circle px-2"
        @click="scrollToTop"
      >
        <b-icon icon="chevron-up" scale="1"></b-icon>
      </b-button>
    </div>
  </div>
</template>

<script>
import store from '../store'
export default {
  name: 'Terms',
  activated () {
    // console.log("terms activated");
    // TODO Why is it only working with this hack?
    window.scrollTo(0, 1)
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 500)
    this.active = true
  },
  data () {
    return {
      active: false
    }
  },
  mounted () {
    const that = this
    document.addEventListener('scroll', function (e) {
      // console.log(window.scrollY , window.innerHeight);
      if (that.active) {
        if (window.scrollY > window.innerHeight / 3) {
          document
            .getElementById('back-to-top-button')
            .classList.remove('opacity-zero')
        } else {
          document
            .getElementById('back-to-top-button')
            .classList.add('opacity-zero')
        }
      }
    })
  },
  deactivated () {
    this.active = false
  },
  methods: {
    scrollToTop () {
      window.scrollTo(0, 0)
    },
    routerGoBack () {
      if (this.fullscreenState) {
        this.toggleFullscreen()
      }
      // this.$router.go(-1);
      this.$router.push({ path: '/#' + store.state.activeHomeViewHash })
    }
  }
}
</script>
<style scoped lang="scss">
#back-to-top {
  pointer-events: auto;
}
.right-bottom {
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 250;
  justify-content: flex-end;
}
.opacity-zero {
  opacity: 0;

  transition: all 0.5s !important;
  -webkit-transition: all 0.5s !important;
  -moz-transition: all 0.5s !important;
  -o-transition: all 0.5s !important;
}
#container-terms {
  width: 100vw;
  height: 100vh;
  position: absolute;
  text-align: left;
  padding: 100px;
  margin-bottom: 100px;
  top: 0;
}
.left-top {
  position: fixed;
  top: 100px;
  left: 40px;
  z-index: 25;
}
.btn-primary {
  color: black;
  background-color: #00ffd6;
  border-color: #00ffd6;
}
.btn-primary:hover {
  color: #212529;
  background-color: #00977e;
  border-color: #00977e;
}
.btn-primary:focus,
.btn-primary.focus {
  box-shadow: none;
  color: black;
  background-color: #00ffd6;
  border-color: #00ffd6;
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #212529;
  background-color: #00977e;
  border-color: #00977e;
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem #00977e77;
  color: black;
  background-color: #00ffd6;
  border-color: #00ffd6;
}
</style>
